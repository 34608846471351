import React from 'react';
import SimpleBar from 'simplebar-react';

type IPageBodyProps = {
	children: any;
};

export const PageBodyScroll = ({ children }: IPageBodyProps) => {
	return (
		<>
			<div className='nk-content-inner'>
				<div className='nk-content-body'>
					<div className='nk-content-overflow'>
						<SimpleBar forceVisible='x' autoHide={false} className='nk-content-view'>
							{children}
						</SimpleBar>
					</div>
				</div>
			</div>
		</>
	);
};
