import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	SwitchBox,
	TextAreaBox,
	TextBox,
} from '../../../../components/base';
import { CheckedBoxListRole } from '../../../../components/shared/system';
import { UserGroupModel, df_UserGroupModel, valid_UserGroupModel } from '../../../../context/models/my-system/users-group/UserGroupModel';

type IUsersGroupDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: UserGroupModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: UserGroupModel) => void;
};

const UsersGroupDetailModal = (props: IUsersGroupDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<UserGroupModel>(detailModel || df_UserGroupModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<UserGroupModel>({
		resolver: yupResolver(valid_UserGroupModel),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: UserGroupModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='xl' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='row g-gs'>
							<div className='col-lg-6'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Thông tin vai trò</h5>
										</div>
										<div className='gy-3'>
											<div className='row g-3 align-center'>
												<div className='col-lg-12'>
													<TextBox
														name={'name'}
														error={errors.name?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, name: value }));
														}}
														value={initialValues.name}
														hasValid={true}
														label='Tên vai trò'
													/>
													<TextAreaBox
														name={'description'}
														error={errors.description?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, description: value }));
														}}
														value={initialValues.description}
														hasValid={true}
														label='Mô tả'
													/>
													<FormGroup label='Trạng thái'>
														<SwitchBox
															name='is_active'
															label='Áp dụng'
															error={errors.is_active?.message}
															onValueChanged={(value) => {
																setInitialValues((prev) => ({ ...prev, is_active: value }));
															}}
															value={initialValues.is_active}
															sizeClass='sm'
														/>
													</FormGroup>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-6'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Phân quyền</h5>
										</div>
										<CheckedBoxListRole
											name='role_ids'
											hasValid={true}
											error={errors.role_ids?.message}
											onSelectionChanged={(value: any[]) => {
												setInitialValues((prev) => ({ ...prev, role_ids: value?.filter((x) => x.length > 0).join(',') }));
											}}
											selectedValue={initialValues.role_ids?.split(',')?.filter((x) => x.length > 0)}
										/>
									</div>
								</div>
							</div>
						</div>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên tác nhân'
						/>
						<TextAreaBox
							name={'description'}
							error={errors.description?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, description: value }));
							}}
							value={initialValues.description}
							hasValid={true}
							label='Mô tả'
						/>
						<CheckedBoxListRole
							name='role_ids'
							label='Quyền hạn'
							hasValid={true}
							error={errors.role_ids?.message}
							onSelectionChanged={(value: any[]) => {
								setInitialValues((prev) => ({ ...prev, role_ids: value?.filter((x) => x.length > 0).join(',') }));
							}}
							selectedValue={initialValues.role_ids?.split(',')?.filter((x) => x.length > 0)}
						/>
						<FormGroup label='Trạng thái'>
							<SwitchBox
								name='is_active'
								label='Áp dụng'
								error={errors.is_active?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, is_active: value }));
								}}
								value={initialValues.is_active}
								sizeClass='sm'
							/>
						</FormGroup>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default UsersGroupDetailModal;
