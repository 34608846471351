interface IPageHeaderProps {
	title?: string;
	children?: any;
}

export const PageHeader = ({ children, title }: IPageHeaderProps) => {
	return (
		<>
			<div className='nk-block-head py-2'>
				<div className='nk-block-between g-3'>
					{title && (
						<div className='nk-block-head-content'>
							<h5 className='nk-block-title page-title'>{title}</h5>
						</div>
					)}
					<div className='nk-block-head-content'>{children}</div>
				</div>
			</div>
		</>
	);
};
