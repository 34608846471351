import { useCallback, useMemo } from 'react';
import { useAuth } from '../../../utils/AuthContext';
import { Button, ButtonSave } from '../../../components/base';
import { ArticleSaveRule, eArticleStatus } from '../../../context/models/articles/eArticleStatus';

type IArticleHeaderButtonProps = {
	articleStatus: string;
	isOwner?: boolean;
	isSaving: boolean;
	isActionDisable: boolean;
	handleSubmit: () => void;
	onActionConfirm: (actionChange: eArticleStatus) => void;
};

export const ArticleButton = ({
	articleStatus,
	isOwner,
	isSaving,
	isActionDisable,
	handleSubmit,
	onActionConfirm,
}: IArticleHeaderButtonProps) => {
	const { account } = useAuth();

	const actionsRule = useMemo(() => {
		const articleSaveRoles = account?.roles;
		return ArticleSaveRule({ status: articleStatus, isOwner: isOwner || false, articleSaveRoles: articleSaveRoles || [] });
	}, [articleStatus, isOwner, account]);

	if (!account || account == null) return null;

	return (
		<>
			{actionsRule.includes(eArticleStatus.EDITED) && isActionDisable && (
				<>
					<li>
						<ButtonSave
							type='button'
							theme='dark'
							preset='dimoutline'
							isLoading={isSaving}
							isDisabled={isSaving}
							onClick={() => {
								handleSubmit();
							}}
							text={`Lưu thay đổi`}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.RETURN) && isActionDisable && (
				<>
					<li>
						<Button
							theme='warning'
							preset='dimoutline'
							onClick={() => onActionConfirm(eArticleStatus.RETURN)}
							text='Thu hồi'
							icon='icon ni ni-reply-fill'
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.BACK) && isActionDisable && (
				<>
					<li>
						<Button
							theme='warning'
							preset='dimoutline'
							onClick={() => onActionConfirm(eArticleStatus.BACK)}
							text='Trả bài'
							icon='icon ni ni-reply-fill'
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.SENTEDITING) && isActionDisable && (
				<>
					<li>
						<Button
							theme='info'
							preset='dimoutline'
							onClick={() => onActionConfirm(eArticleStatus.SENTEDITING)}
							text='Gửi biên tập'
							icon='icon ni ni-send-alt'
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.SENTAPPROVING) && isActionDisable && (
				<>
					<li>
						<Button
							theme='warning'
							preset='dimoutline'
							onClick={() => onActionConfirm(eArticleStatus.SENTAPPROVING)}
							text='Gửi duyệt'
							icon='icon ni ni-send'
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.PUBLISHED) && isActionDisable && (
				<>
					<li>
						<Button
							theme='primary'
							onClick={() => onActionConfirm(eArticleStatus.PUBLISHED)}
							text='Xuất bản ngay'
							icon='icon ni ni-check-thick'
							sizeClass='sm'
						/>
					</li>
				</>
			)}

			{actionsRule.includes(eArticleStatus.REMOVED) && (
				<>
					<li>
						<Button
							text={`Hủy xuất bản`}
							icon='icon ni ni-spark-off-fill'
							className='d-md-inline-flex'
							theme='danger'
							onClick={() => onActionConfirm(eArticleStatus.REMOVED)}
						/>
					</li>
				</>
			)}
		</>
	);
};
