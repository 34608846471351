import { Outlet } from 'react-router';

const PublicLayout = () => {
	return (
		<>
			<div className='nk-body ui-rounder npc-general pg-auth no-touch nk-nio-theme'>
				<div className='nk-app-root'>
					<div className='nk-main'>
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
};

export default PublicLayout;
