import { yupResolver } from '@hookform/resolvers/yup';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BaseForm, CheckBoxList, DxDateBox, FormGroup, LoadingPanel, SwitchBox, TextAreaBox, TextBox } from '../../../components/base';
import { DxSelectBox } from '../../../components/base/DxSelectBox';
import { ImageBox } from '../../../components/base/ImageBox';
import TextEditor from '../../../components/base/TextEditor';
import DxTreeList, { DxTreeListColumn } from '../../../components/common/DxTreeList';
import { BlockCard, PageContainer, PageHeader } from '../../../components/container';
import { PageBodyScroll } from '../../../components/container/PageBodyScroll';
import { ComboBoxArticleCategory } from '../../../components/shared/hoc-article-category/ComboBoxArticleCategory';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { ArticleActionConfirmRequest } from '../../../context/models/articles/ArticleActionConfirmRequest';
import { ArticleSaveModel, dfArticleModel, validArticleSchema } from '../../../context/models/articles/ArticleModel';
import { eArticleGenresData } from '../../../context/models/articles/eArticleGenres';
import { eArticleStatus, getArticleStatusName } from '../../../context/models/articles/eArticleStatus';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { useAuth } from '../../../utils/AuthContext';
import { slugHelper } from '../../../utils/slugHelper';
import { ArticleButton } from '../button-actions/ArticleButton';
import { ChangeStatusConfirm } from '../change-status-confirm';
import { TagBoxArticleTag } from '../tagbox-article-tag/TagBoxArticleTag';
import { eArticleArchivesData } from '../../../context/models/articles/eArticleArchives';
import { RelatedArticles } from '../related-articles';

const dataSourcePhanLoai = [
	{ text: 'Bài dẫn từ nguồn khác', value: 3, group: 'Bài dẫn' },
	{ text: 'Bài chuyên gia', value: 14, group: 'Bài tự sản xuất' },
	{ text: 'Bài tổng hợp 1 (Thị trường, Giá cả, Kiến thức đầu tư)', value: 4, group: 'Bài tự sản xuất' },
	{ text: 'Bài tổng hợp 2 (Chứng khoán, Kinh tế, Doanh nghiệp)', value: 15, group: 'Bài tự sản xuất' },
	{ text: 'Bài tự sản xuất', value: 9, group: 'Bài tự sản xuất' },
	{ text: 'Bài độc quyền', value: 8, group: 'Bài tự sản xuất' },
	{ text: 'Bài quảng cáo', value: 7, group: 'Bài quảng cáo' },
	{ text: 'Video dẫn từ nguồn khác', value: 5, group: 'Video dẫn' },
	{ text: 'Video Chứng khoán, doanh nghiệp', value: 13, group: 'Video tự sản xuất' },
	{ text: 'Video thị trường hàng hóa', value: 11, group: 'Video tự sản xuất' },
];

const ArticleEditPage = () => {
	const { id } = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const redirectTo = useMemo(() => {
		return location.state?.from || '/articles-template';
	}, [location]);

	const currentId = useMemo(() => {
		return parseInt(id || '0');
	}, [id]);

	const isAddMode = useMemo(() => {
		return currentId === 0;
	}, [currentId]);

	const { account } = useAuth();

	const allowCategories = useMemo(() => {
		return account?.categories || [];
	}, [account]);

	const butDanhs = useMemo(() => {
		return account?.but_danhs?.map((item, idx) => ({ id: idx, title: item })) || [];
	}, [account]);

	const { detailEditResponse, status, showConfirm, selectedIds } = useSelector((state: AppState) => state.article);
	const [initialValues, setInitialValues] = useState<ArticleSaveModel>(dfArticleModel);
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<ArticleSaveModel>({
		resolver: yupResolver(validArticleSchema) as any,
		defaultValues: initialValues,
	});

	const onShowConfirm = (data: ArticleActionConfirmRequest) => {
		dispatch(actions.article.showConfirm(data));
	};

	const handleGetDetail = useCallback(() => {
		dispatch(actions.article.detailEditRequest(currentId));
	}, [currentId]); //eslint-disable-line react-hooks/exhaustive-deps

	const handleSaveData = (data: ArticleSaveModel) => {
		dispatch(actions.article.saveRequest(data));
	};

	const onSubmitHandler = (values: ArticleSaveModel) => {
		handleSaveData({ ...values });
	};

	const watchChangeName = useWatch({ control, name: 'title', defaultValue: '' });

	useEffect(() => {
		if (isAddMode) {
			const slug = slugHelper.toSlug(watchChangeName);
			setValue('titleSlug', slug);
			setInitialValues((prev) => ({ ...prev, titleSlug: slug }));
		}
	}, [watchChangeName, setValue, isAddMode]);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	useEffect(() => {
		if (detailEditResponse) {
			setInitialValues((prev) => ({
				...prev,
				...detailEditResponse,
				publishedOnDate: moment(detailEditResponse.publishedOnDate).format(),
				actionStatus: eArticleStatus.EDITED,
			}));
		} else {
			setInitialValues((prev) => ({
				...prev,
				publishedOnDate: moment().format(),
				actionStatus: eArticleStatus.DRAFT,
			}));
		}
	}, [detailEditResponse]);

	useEffect(() => {
		if (currentId > 0) {
			handleGetDetail();
		} else {
			setInitialValues(dfArticleModel);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentId]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) navigate(redirectTo, { replace: true });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, redirectTo]);

	const fromUngroupedData = new DataSource({
		store: {
			type: 'array',
			data: dataSourcePhanLoai,
			key: 'value',
		},
		group: 'group',
	});

	const eArticleArchivesDataSource = useMemo(() => {
		const currentCategory = allowCategories.find((x) => x.id === initialValues.catId);
		const result = eArticleArchivesData.map((item) => {
			const pageTitle = item.by_category ? currentCategory?.name ?? 'Chuyên mục' : 'Trang chủ';
			return { ...item, title: `[${pageTitle}] ${item.title}` };
		});
		return result.sort((a, b) => a.sort_order - b.sort_order);
	}, [allowCategories, initialValues.catId]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Viết nội dung Tin bài`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<ArticleButton
								isOwner={true}
								articleStatus={detailEditResponse?.status || eArticleStatus.DRAFT}
								isActionDisable={true}
								onActionConfirm={(actionChange) => {
									onShowConfirm({ isShow: true, actionStatus: actionChange, ids: [] });
									setInitialValues((prev) => ({ ...prev, actionStatus: actionChange }));
								}}
								handleSubmit={handleSubmit(onSubmitHandler)}
								isSaving={status === eBaseActionStatus.saving}
							/>
						</ul>
					</div>
				</PageHeader>
				<PageBodyScroll>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingPanel />}
						<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
							<div className='gy-3'>
								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<TextAreaBox
											name='title'
											label='Tiêu đề bài viết'
											hasValid={true}
											value={initialValues.title}
											error={errors.title?.message}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, title: value }));
											}}
											className='no-resize editor-f-18'
											sizeClass='sm'
											placeholder='Nhập tiêu đề bài viết...'
											helpBlock='Tiêu đề bài viết tối thiểu 0 và tối đa 87 ký tự!'
										/>
										<SwitchBox
											name='isHiddenContent'
											value={initialValues.isHiddenContent}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, isHiddenContent: value }));
											}}
											sizeClass='md'
											label='Không hiển thị nội dung với các công cụ tìm kiếm: google, bing...'
										/>
										<TextAreaBox
											name='initContent'
											label='Trích lược/Sapo'
											value={initialValues.initContent || ''}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, initContent: value }));
											}}
											className='editor-f-18'
											sizeClass='sm'
											placeholder='Nhập trích lược/sapo...'
											rows={5}
										/>
									</div>
								</div>
								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<ImageBox
											name='image'
											label='Ảnh đại diện'
											value={initialValues.image || ''}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, image: value || '' }));
											}}
										/>
										<TextBox
											name='imageCaption'
											label='Chú thích ảnh đại diện'
											placeholder='Nhập chú thích ảnh đại diện'
											value={initialValues.imageCaption || ''}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, imageCaption: value }));
											}}
											sizeClass='md'
										/>
									</div>
								</div>
								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<div className='row'>
											<div className='col-xl-6'>
												<DxSelectBox
													name='articleType'
													label='Phân loại bài viết'
													hasValid={true}
													value={initialValues.articleType}
													dataSource={fromUngroupedData}
													error={errors.articleType?.message}
													grouped={true}
													valueExpr='value'
													displayExpr='text'
													placeholder='Phân loại bài viết'
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, articleType: value }));
													}}
													sizeClass='xs'
												/>
											</div>
											<div className='col-xl-6'>
												<DxSelectBox
													name='genre'
													label='Thể loại bài viết'
													hasValid={true}
													value={initialValues.genre}
													dataSource={eArticleGenresData}
													error={errors.genre?.message}
													valueExpr='Code'
													displayExpr='Name'
													placeholder='Thể loại bài viết'
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, genre: value }));
													}}
													sizeClass='xs'
													helpBlock='Sử dụng khi thay đổi giao diện hiển thi bài viết theo thể loại cụ thể!'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<div className='row'>
											<div className='col-md-6'>
												<ComboBoxArticleCategory
													name='catId'
													label='Chuyên mục bài viết'
													hasValid={true}
													placeholder='Chọn chuyên mục bài viết'
													value={initialValues.catId}
													error={errors.catId?.message}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, catId: value }));
													}}
													sizeClass='md'
												/>
												{/* <FormGroup label='Chuyên mục phụ'>
													<DxTreeList
														dataSource={allowCategories}
														keyExpr='id'
														parentIdExpr='parentId'
														selectionMode='multiple'
														value={initialValues.subCatIds?.split(',') || []}
														onValueChanged={(selected) => {
															setInitialValues((prev) => ({ ...prev, subCatIds: selected ? selected.join(',') : '' }));
														}}
														height={300}
													>
														<DxTreeListColumn dataField='name' caption='Tên chuyên mục' />
													</DxTreeList>
												</FormGroup> */}
											</div>
											<div className='col-md-6'>
												<FormGroup label='Thiết lập vùng hiển thị'>
													<DxTreeList
														dataSource={eArticleArchivesDataSource}
														keyExpr='key'
														parentIdExpr='parent_key'
														selectionMode='multiple'
														value={initialValues.articleTypeNewsConfigs?.split(',') || []}
														onValueChanged={(selected) => {
															setInitialValues((prev) => ({ ...prev, articleTypeNewsConfigs: selected ? selected.join(',') : '' }));
														}}
														height={300}
													>
														<DxTreeListColumn dataField='title' caption='[Trang hiển thị] Tên vùng hiển thị' />
													</DxTreeList>
												</FormGroup>
											</div>
										</div>
									</div>
								</div>

								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<div className='row'>
											<div className='col-md-4'>
												<DxDateBox
													name='publishedOnDate'
													label='Ngày xuất bản'
													hasValid={true}
													value={initialValues.publishedOnDate}
													displayFormat={'dd/MM/yyyy HH:mm'}
													typeDate='datetime'
													onValueChanged={(e) => {
														setInitialValues((prev) => ({ ...prev, publishedOnDate: e.value }));
													}}
												/>
											</div>
											<div className='col-md-8'>
												<TextBox
													name='sourceLink'
													label='Nguồn bài viết'
													value={initialValues.sourceLink || ''}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, sourceLink: value }));
													}}
													sizeClass='sm'
													placeholder='Nguồn bài viết'
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='row g-3 align-center'>
									<div className='col-lg-12'>
										<div className='form-group'>
											<label className='form-label'>
												Nội dung
												<em className='text-danger'>(*)</em>
											</label>
										</div>
									</div>
									<div className='col-lg-12'>
										<TextEditor
											name='contentHtml'
											value={initialValues.contentHtml || ''}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, contentHtml: value }));
											}}
										/>
									</div>
								</div>
								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<div className='row'>
											<div className='col-xl-4 col-md-6'>
												<DxSelectBox
													name='selectAuthorPublish'
													label='Chọn Bút danh'
													value={initialValues.authorPublish}
													dataSource={butDanhs}
													valueExpr='title'
													displayExpr='title'
													placeholder='Chọn Bút danh'
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, authorPublish: value }));
													}}
													sizeClass='xs'
												/>
											</div>
											<div className='col-xl-4 col-md-6'>
												<TextBox
													name='authorPublish'
													label='Hoặc nhập Bút danh'
													placeholder='Nhập bút danh'
													value={initialValues.authorPublish || ''}
													onValueChanged={(value) => {
														setInitialValues((prev) => ({ ...prev, authorPublish: value }));
													}}
													sizeClass='md'
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<FormGroup label='SEO' className='border rounded p-2'>
											<TextBox
												name='metaTitle'
												label='Meta Title'
												value={initialValues.metaTitle || ''}
												onValueChanged={(value) => {
													setInitialValues((prev) => ({ ...prev, metaTitle: value }));
												}}
												sizeClass='sm'
												placeholder='Meta Title'
											/>
											<TextBox
												name='titleSlug'
												label='Link hiển thị'
												hasValid={true}
												value={initialValues.titleSlug}
												error={errors.titleSlug?.message}
												onValueChanged={(value) => {
													setInitialValues((prev) => ({ ...prev, titleSlug: value }));
												}}
												sizeClass='md'
												className='editor-f-16'
												placeholder='Link hiển thị: tieu-de-tin-bai'
												helpBlock='Link hiển thị: tieu-de-tin-bai'
											/>
											<TextAreaBox
												name='metaDescription'
												label='Meta Description'
												value={initialValues.metaDescription || ''}
												onValueChanged={(value) => {
													setInitialValues((prev) => ({ ...prev, metaDescription: value }));
												}}
												sizeClass='sm'
												placeholder='Meta Description'
											/>
											<TagBoxArticleTag
												name='tags'
												label='Tags/từ khóa'
												isRequired
												inputAttr='tags'
												placeholder='Thêm tags cho bài viết'
												isClearable
												isSearchable
												value={initialValues.tags ? initialValues.tags.split(',') : []}
												handleValueChange={(value) => {
													const newTags = value.join(',');
													setInitialValues((prev) => ({ ...prev, tags: newTags }));
												}}
												error={errors.tags?.message}
											/>
										</FormGroup>
									</div>
								</div>
								{/* <div className='row g-3 align-center'>
									<div className='col-lg-4'>
										<div className='form-group'>
											<label className='form-label'>Trạng thái hiển thị</label>
										</div>
									</div>
									<div className='col-xl-8'>
										<SwitchBox
											name='isAMP'
											value={initialValues.isAMP}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, isAMP: value }));
											}}
											sizeClass='sm'
											label='AMP'
										/>
										{'   '}
										
									</div>
								</div> */}
								<div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<FormGroup label='Bài viết liên quan'>
											<RelatedArticles
												articleIds={initialValues.relationIds || ''}
												onValueChanged={(value) => {
													setInitialValues((prev) => ({ ...prev, relationIds: value }));
												}}
											/>
										</FormGroup>
									</div>
								</div>
								{/* <div className='row g-3 align-center'>
									<div className='col-xl-8'>
										<CheckBoxList
											name='archives'
											selectedValue={[]}
											dataSource={eArticleArchivesDataSource}
											sizeClass='sm'
											displayExpr='title'
											keyExpr='key'
											onSelectionChanged={(value: number[]) => {
												// setInitialValues((prev) => ({ ...prev, roles: value }));
											}}
										/>
									</div>
								</div> */}
							</div>

							{/* <div className='row g-4'>
								<div className='col-xl-12'>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='status'>Trạng thái</h6>
										</div>
									
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='genre'>Vùng hiển thị</h6>
										</div>
										<CheckBoxList
											name='archives'
											selectedValue={[]}
											dataSource={eArticleArchivesData}
											sizeClass='sm'
											displayExpr='Name'
											keyExpr='Code'
											onSelectionChanged={(value: number[]) => {
												// setInitialValues((prev) => ({ ...prev, roles: value }));
											}}
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='authorPublish'>Bút danh</h6>
										</div>
										<TextBox
											name='authorPublish'
											value={initialValues.authorPublish}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, authorPublish: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='sourceLink'>Nguồn</h6>
										</div>
										<TextBox
											name='sourceLink'
											value={initialValues.sourceLink}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, sourceLink: value }));
											}}
											sizeClass='sm'
											className='form-control-simple editor-focus  editor-f-18'
											classWrapper='border-bottom'
										/>
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Meta Tile</h6>
										</div>
									
									</div>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaDescription'>Meta Description</h6>
										</div>
									
									</div>
								</div>
							</div> */}
						</BaseForm>
					</BlockCard>
				</PageBodyScroll>
			</PageContainer>
			{showConfirm && showConfirm.isShow && (
				<>
					<ChangeStatusConfirm
						isShow={showConfirm.isShow}
						onClose={() => onShowConfirm({ isShow: false, actionStatus: showConfirm.actionStatus, ids: [] })}
						onConfirmStatus={handleSubmit(onSubmitHandler)}
						isLoading={status === eBaseActionStatus.loading}
						mesageText={`Bạn chắc chắc muôn ${getArticleStatusName(showConfirm.actionStatus)} bài viết này?`}
						actionStatus={showConfirm.actionStatus}
						selectedIds={selectedIds}
					/>
				</>
			)}
		</>
	);
};

export default ArticleEditPage;
