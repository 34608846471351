/* eslint-disable react-refresh/only-export-components */

import { Dispatch } from 'redux';
import { ChannelActionTypes } from '../../context/stores/channel/IChannelActionsTypes';
import { actions } from '../../context/stores/rootActions';
import { ChannelModel } from '../../context/models/channel/ChannelModel';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { AppState } from '../../context/stores/rootReducers';
import { useEffect, useMemo } from 'react';
import DxTable, { DxTableColumn } from '../../components/common/DxTable';
import { createUUID } from '../../utils/createUUID';
import { ButtonLink, LoadingTable } from '../../components/base';
import React from 'react';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { connect } from 'react-redux';

type IConfigChannelPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allChannels: ChannelModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<ChannelModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: ChannelModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<ChannelModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Thiết lập chuyên trang`,
		status: state.channel.status,
		allChannels: state.channel.allChannels,
		selectedIds: state.channel.selectedIds,
		showDetail: state.channel.showDetail,
		showConfirm: state.channel.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<ChannelActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.channel.getAllRequest());
	},
	handleSaveData: (data: ChannelModel) => {
		dispatch(actions.channel.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.channel.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.channel.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<ChannelModel>) => {
		dispatch(actions.channel.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.channel.showConfirm(isShow));
	},
});

const ConfigChannelPage = (props: IConfigChannelPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allChannels,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	// const dataSource = useMemo(() => {
	// 	return allRoyalties.map((royalty) => ({
	// 		...royalty,
	// 		minView: toNumberWithPeriod(parseInt(royalty.minView)),
	// 		money: `${toNumberWithPeriod(parseInt(royalty.money, 10))} ₫`,
	// 	}));
	// }, [allRoyalties]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên`} width={300}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='sort_order' width={50} caption={`Thứ tự`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='domain' caption={`Tên miền`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allChannels]);

	return (
		<React.Fragment>
			<PageContainer>
				<PageHeader title={`${titlePage}`}>
					{/* <div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div> */}
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allChannels}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
		</React.Fragment>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigChannelPage);
