import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo_cms from '../../../assets/images/logo-dark2x.png';
import { BaseForm, Button, PasswordBox, TextBox } from '../../../components/base';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { LoginRequest, valid_LoginRequest } from '../../../context/models/my-system/accounts';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { BlockCard } from '../../../components/container';
import { useAuth } from '../../../utils/AuthContext';

const LoginPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from || '/';

	const { account, login } = useAuth();

	const { loginStatus, loginResponse } = useSelector((state: AppState) => state.account);
	const loginStart = (loginRequest: LoginRequest) => dispatch(actions.account.loginRequest(loginRequest));

	const [initialValues, setInitialValues] = useState<LoginRequest>({
		user_name: '',
		password: '',
	});

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<LoginRequest>({
		resolver: yupResolver(valid_LoginRequest),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: LoginRequest) => {
		loginStart(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	useEffect(() => {
		if (account != null) navigate(from, { replace: true });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	useEffect(() => {
		if (loginStatus === eBaseActionStatus.complete && loginResponse) login(loginResponse?.account_info || null);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginStatus]);

	return (
		<>
			<div className='nk-wrap nk-wrap-nosidebar'>
				<div className='nk-content'>
					<div className='nk-block nk-block-middle nk-auth-body wide-xs'>
						<div className='brand-logo pb-4 text-center'>
							<img className='logo-dark logo-img-md' src={logo_cms} alt='Đăng nhập' />
						</div>
						<BlockCard>
							<div className='nk-block-head'>
								<div className='nk-block-head-content'>
									<h4 className='nk-block-title'>Đăng nhập</h4>
								</div>
							</div>
							<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
								<TextBox
									name='user_name'
									error={errors.user_name?.message}
									onValueChanged={(value) => {
										setInitialValues((prev) => ({ ...prev, user_name: value }));
									}}
									value={initialValues.user_name}
									sizeClass='lg'
									label='Tài khoản hoặc Email'
									placeholder='Nhập Tài khoản hoặc Email'
									hasValid={true}
								/>
								<PasswordBox
									name='password'
									error={errors.password?.message}
									onValueChanged={(value) => {
										setInitialValues((prev) => ({ ...prev, password: value }));
									}}
									value={initialValues.password}
									sizeClass={'lg'}
									label='Mật khẩu'
									placeholder='Nhập mật khẩu'
									hasValid={true}
								/>
								<Button
									type='submit'
									text='Đăng nhập'
									icon='icon ni ni-signin'
									onClick={() => {}}
									isDisabled={loginStatus === eBaseActionStatus.loading}
									isLoading={loginStatus === eBaseActionStatus.loading}
									theme='primary'
									sizeClass='lg'
									className='btn-block'
								/>
							</BaseForm>
						</BlockCard>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginPage;
