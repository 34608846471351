export type ArticleSelectRequest = {
	searchString: string;
	catId: number;
	author: string;
	status: string;
	beginDate: string;
	endDate: string;
	langCode: string;
	page_index: number;
	page_size: number;
};

export const df_ArticleSelectRequest: ArticleSelectRequest = {
	author: '',
	beginDate: '1900-01-01',
	catId: 0,
	endDate: '1900-01-01',
	langCode: '',
	searchString: '',
	status: '',
	page_index: 1,
	page_size: 100
};

