import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';

export function useLookupRoleScopes() {
	const dispatch = useDispatch();
	const { allRoleScopes } = useSelector((state: AppState) => state.roleScopes);
	useEffect(() => {
		if (allRoleScopes.length <= 0) dispatch(actions.roleScopes.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { role_scopes_lookup: allRoleScopes };
}
