import { createUUID } from '../../../utils/createUUID';
import { TreeList, Selection, Column as DxTreeListColumn, FilterRow } from 'devextreme-react/tree-list';

export { DxTreeListColumn };

type IDxTreeListProps = {
	value: any[];
	dataSource: any[];
	keyExpr: string;
	parentIdExpr: string;
	selectionMode?: 'multiple' | 'single';
	selectionDataMode?: 'all' | 'excludeRecursive' | 'leavesOnly';
	selectionRecursive?: boolean;
	expandedRowKeys?: any[];
	onValueChanged: (e: any[]) => void;
	height?: string | number | undefined;
	width?: string | number | undefined;
	showRowLines?: boolean;
	showBorders?: boolean;
	columnAutoWidth?: boolean;
	children?: React.ReactNode;
	wordWrapEnabled?: boolean;
};

const DxTreeList = (props: IDxTreeListProps) => {
	const {
		value,
		dataSource,
		selectionMode,
		selectionDataMode,
		selectionRecursive,
		expandedRowKeys,
		onValueChanged,
		columnAutoWidth,
		keyExpr,
		parentIdExpr,
		height,
		showBorders,
		width,
		showRowLines,
		children,
		wordWrapEnabled,
	} = props;

	return (
		<TreeList
			id={`tree-list-${createUUID()}`}
			dataSource={dataSource}
			showRowLines={showRowLines || true}
			showBorders={showBorders || true}
			columnAutoWidth={columnAutoWidth || true}
			defaultExpandedRowKeys={expandedRowKeys || []}
			selectedRowKeys={value}
			keyExpr={keyExpr}
			parentIdExpr={parentIdExpr}
			onSelectionChanged={(e) => {
				const selectedData = e.component?.getSelectedRowsData(selectionDataMode || 'all');
				const selectedKeys = selectedData?.map((item) => item[keyExpr]) || [];
				onValueChanged(selectedKeys);
			}}
			width={width || 'inherit'}
			height={height || 'inherit'}
			wordWrapEnabled={wordWrapEnabled || true}
			scrolling={{ mode: 'standard' }}
			sorting={{ mode: 'none' }}
		>
			<Selection recursive={selectionRecursive || true} mode={selectionMode || 'none'} />
			<FilterRow visible={true} />
			{children}
		</TreeList>
	);
};
export default DxTreeList;
