import { useMemo, useState } from 'react';
import { MenuResponse } from '../../../context/models/my-system/accounts/MenuResponse';
import { useAuth } from '../../../utils/AuthContext';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

interface MenuTree {
	id: number;
	name: string;
	path: string;
	icon: string;
	parent_id: number;
	sort_order: number;
	isActive: boolean;
	childs?: MenuTree[];
}

const buildTree = (parentId: number, menus: MenuResponse[]) => {
	return menus
		.filter((m) => m.parent_id === parentId)
		.map((menu) => {
			const menuTree: MenuTree = {
				...menu,
				isActive: false,
				childs: buildTree(menu.id, menus),
			};
			return menuTree;
		});
};

export const MenuRender = () => {
	const { menus } = useAuth();
	const menuTrees: MenuTree[] = useMemo(() => {
		return menus ? buildTree(0, menus) : [];
	}, [menus]);
	return <>{menuTrees && menuTrees.length > 0 && menuTrees.map((group) => <MenuGroup key={group.id} group={group} />)}</>;
};

const MenuGroup = ({ group }: { group: MenuTree }) => {
	return <>{group && <MenuItem menu={group} />}</>;
};

const MenuSub = ({ menus }: { menus: MenuTree[] }) => {
	return (
		<>
			{menus && menus.length > 0 && (
				<>
					<ul className='nk-menu-sub'>
						{menus.map((menu) => {
							return <MenuItem key={menu.id} menu={menu} />;
						})}
					</ul>
				</>
			)}
		</>
	);
};

const MenuItem = ({ menu }: { menu: MenuTree }) => {
	const [isActive, setIsExpanded] = useState(menu.isActive);
	const location = useLocation();
	const currentActive = useMemo(() => {
		const currentPath = location.pathname.replace(/^\|/, '');
		const menuPath = menu.path.replace(/^\|/, '');
		return currentPath === menuPath || currentPath.includes(menuPath) ? true : false;
	}, [location, menu]);

	return (
		<li
			className={`nk-menu-item ${menu.childs && menu.childs.length > 0 ? 'has-sub' : ''} ${
				isActive || currentActive ? 'active current-page' : ''
			}`}
		>
			<NavLink
				to={menu.path}
				className={`nk-menu-link ${menu.childs && menu.childs.length > 0 ? 'nk-menu-toggle' : ''}`}
				title={menu.name}
				onClick={(e) => {
					if (menu.childs && menu.childs.length > 0) {
						e.preventDefault();
						if (!currentActive) setIsExpanded((prev) => !prev);
					}
				}}
			>
				<span className='nk-menu-icon'>
					<em className={menu.icon || 'icon ni ni-list-thumb'}></em>
				</span>
				<span className='nk-menu-text'>{menu.name}</span>
			</NavLink>
			{menu.childs && <MenuSub menus={menu.childs} />}
		</li>
	);
};
