import { jwtTokenHelper } from '../../../utils/jwtTokenHelper';
import { valid_base, valid_base_object } from '../../base/valid_base';

export type ArticleCategoryModel = {
	id: number;
	name: string;
	channelId: string;
	slug: string;
	parentId: number;
	catTree: string;
	genre: string;
	inActive: boolean;
	sortOrder: number;
	isHidden: boolean;
	sortOrderSub: number;
	isHiddenSub: boolean;
	sortOrderFooter: number;
	isHiddenFooter: boolean;
	langCode: string;
};

export const dfArticleCategoryModel: ArticleCategoryModel = {
	id: 0,
	name: '',
	channelId: '',
	slug: '',
	parentId: 0,
	catTree: 'c0',
	genre: 'CONTENT',
	inActive: false,
	sortOrder: 0,
	isHidden: true,
	sortOrderSub: 0,
	isHiddenSub: true,
	sortOrderFooter: 0,
	isHiddenFooter: true,
	langCode: jwtTokenHelper.LANGCODE(),
};

export const valid_ArticleCategory = () =>
	valid_base_object.object<ArticleCategoryModel>().shape({
		id: valid_base.number_required(),
		name: valid_base.string_required(),
		channelId: valid_base.string_required(),
		slug: valid_base.string_required(),
		parentId: valid_base.number_required(),
		catTree: valid_base.string_required(),
		genre: valid_base.string_required(),
		inActive: valid_base.bool_required(),
		sortOrder: valid_base.number_required(),
		isHidden: valid_base.bool_required(),
		sortOrderSub: valid_base.number_required(),
		isHiddenSub: valid_base.bool_required(),
		sortOrderFooter: valid_base.number_required(),
		isHiddenFooter: valid_base.bool_required(),
		langCode: valid_base.string_required(),
	});
