import { useAuth } from '../../../../utils/AuthContext';
import { DxDropDownBox } from '../../../base/DxDropDownBox';
// import { useLookupArticleCategory } from '../useLookupArticleCategory';

type IComboBoxArticleCategoryProps = {
	name: string;
	value: any;
	valueExpr?: string;
	displayExpr?: string;
	onValueChanged: (value: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	placeholder?: string;
	width?: string | number;
};

const ComboBoxArticleCategory = (props: IComboBoxArticleCategoryProps) => {
	// const { article_category_all } = useLookupArticleCategory();
	const { account } = useAuth();
	const categories = account?.categories || [];
	const { value, onValueChanged, ...rest } = props;
	return (
		<DxDropDownBox
			dataSource={categories}
			value={[value]}
			width={340}
			isSearchable={true}
			onValueChanged={(value) => {
				const seleted_id = value && value.length > 0 ? value[0] : 0;
				onValueChanged(seleted_id);
			}}
			valueExpr='id'
			displayExpr='name'
			parentIdExpr='parentId'
			placeholder={props.placeholder || 'Chọn chuyên mục'}
			{...rest}
		/>
	);
};
export default ComboBoxArticleCategory;
