import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import ArrayStore from 'devextreme/data/array_store';
import TreeView from 'devextreme-react/tree-view';

export type ISelectBoxProps = {
	name: string;
	dataSource: any;
	value: any[];
	onValueChanged: (value: any[]) => void;
	valueExpr: string;
	displayExpr: string;
	parentIdExpr: string;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	hasValid?: boolean;
	width?: any;
	isViewMode?: 'dot_bottom' | 'line_bottom';
};

export const DxDropDownBox = (props: ISelectBoxProps) => {
	const {
		value,
		error,
		name,
		label,
		sizeClass,
		isDisabled,
		helpBlock,
		labelSmall,
		hasValid,
		dataSource,
		onValueChanged,
		valueExpr,
		displayExpr,
		parentIdExpr,
		isClearable,
		placeholder,
		width,
		isViewMode,
		isSearchable,
	} = props;
	const showError = error ? true : false;
	let treeView: any = null;
	const [treeBoxValue, setTreeBoxValue] = useState<any[]>(value);
	const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
	const classBuild = useMemo(() => {
		let classBuild = `select-box form-control-${sizeClass || 'xs'}`;
		if (showError) classBuild = `${classBuild} error`;
		if (isViewMode) classBuild = `${classBuild} ${isViewMode}`;
		return classBuild;
	}, [sizeClass, showError, isViewMode]);

	const treeDataSource = useMemo(() => {
		return new ArrayStore({
			key: valueExpr,
			data: dataSource || [],
		});
	}, [dataSource, valueExpr]);

	const syncTreeViewSelection = (e: any) => {
		setTreeBoxValue(e.value);
		if (!treeView) return;
		console.log('object, e.value', e.value);
		if (!e.value) {
			treeView.instance.unselectAll();
		} else {
			treeView.instance.selectItem(e.value);
		}
	};

	useEffect(() => {
		setTreeBoxValue(value);
	}, [value]);

	const treeViewRender = useCallback(() => {
		return (
			<TreeView
				dataSource={treeDataSource}
				ref={(ref) => {
					treeView = ref;
				}}
				dataStructure='plain'
				keyExpr={valueExpr}
				parentIdExpr={parentIdExpr}
				selectionMode='single'
				displayExpr={displayExpr}
				searchEnabled={isSearchable}
				showCheckBoxesMode='normal'
				selectByClick={true}
				selectedItemKeys={treeBoxValue}
				onContentReady={(e) => {
					const items = e.component.getDataSource().items();
					const selelected = items?.find((item) => treeBoxValue.includes(item[valueExpr]));
				
					if(selelected){
						e.component.selectItem(selelected);
						const selectedParent = items?.find((item) => item[valueExpr] === selelected[parentIdExpr]);
						e.component.expandItem(selectedParent);
					}
				}}
				onItemClick={(e) => {
					setIsTreeBoxOpened(false);
				}}
				onItemSelectionChanged={(e) => {
					setTreeBoxValue(e.component.getSelectedNodeKeys() || []);
					onValueChanged(e.component.getSelectedNodeKeys() || []);
				}}
			/>
		);
	}, [treeBoxValue, treeDataSource, parentIdExpr, isSearchable, valueExpr, displayExpr]);

	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<DropDownBox
						className={classBuild}
						id={name}
						inputAttr={{ 'aria-label': 'Owner' }}
						valueExpr={valueExpr}
						displayExpr={displayExpr}
						dataSource={treeDataSource}
						disabled={isDisabled || (isViewMode && isViewMode.length > 0)}
						showClearButton={isClearable}
						width={width}
						name={name}
						value={treeBoxValue}
						onValueChanged={(e) => {
							syncTreeViewSelection(e);
						}}
						placeholder={placeholder}
						opened={isTreeBoxOpened}
						onOptionChanged={(e) => {
							if (e.name === 'opened') {
								setIsTreeBoxOpened(e.value);
							}
						}}
						contentRender={treeViewRender}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
