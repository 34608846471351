import { useEffect, useState } from 'react';
import { ArticleTypeModel, dfArticleTypeModel, valid_ArticleType } from '../../../context/models/articles-type/ArticleTypeModel';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BaseForm, ButtonCancel, ButtonSave, ModalBody, ModalDraggable, ModalFooter, ModalHeader, TextBox } from '../../../components/base';

type IArticleTypeDetailProps = {
	isShow: boolean;
	title: string;
	detailModel?: ArticleTypeModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: ArticleTypeModel) => void;
};

const ArticleTypeDetail = (props: IArticleTypeDetailProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<ArticleTypeModel>(dfArticleTypeModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ArticleTypeModel>({
		resolver: yupResolver(valid_ArticleType()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: ArticleTypeModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
	}, [detailModel]);

	return (
		<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
			<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
			<ModalBody>
				<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
					<TextBox
						name={'name'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, name: value }));
						}}
						value={initialValues.name}
						hasValid={true}
						label='Tên loại tin bài'
					/>
					<TextBox
						name={'description'}
						error={errors.name?.message}
						onValueChanged={(value) => setInitialValues((prev) => ({ ...prev, description: value }))}
						value={initialValues.description}
						hasValid={true}
						label='Mô tả'
					/>
					<TextBox
						name={'sort_order'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, sort_order: value }));
						}}
						value={initialValues.sort_order.toString()}
						hasValid={true}
						label='Thứ tự'
					/>
					<TextBox
						name={'money'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, money: value }));
						}}
						value={initialValues.money.toString().replace(' ₫', '').replace('.', '')}
						hasValid={true}
						label='Giá tiền'
					/>
				</BaseForm>
			</ModalBody>
			<ModalFooter>
				<ButtonCancel onClick={onClose} isDisabled={isSaving} />
				<ButtonSave
					type='button'
					isLoading={isSaving}
					isDisabled={isSaving}
					onClick={handleSubmit(onSubmitHandler)}
					text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
				/>
			</ModalFooter>
		</ModalDraggable>
	);
};

export default ArticleTypeDetail;
