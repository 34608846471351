import { BasePaginationResponse } from '../../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../../base/BaseShowDetail';
import { BaseAction } from '../../../base/IBaseActionRespone';
import { UserGroupModel } from '../../../models/my-system/users-group/UserGroupModel';
import {
	UserGroupAction_CHANGE_SELECTED_IDS,
	UserGroupAction_DELETE_Failure,
	UserGroupAction_DELETE_Request,
	UserGroupAction_DELETE_Success,
	UserGroupAction_GETALL_Failure,
	UserGroupAction_GETALL_Request,
	UserGroupAction_GETALL_Success,
	UserGroupAction_GETPAGINATION_Failure,
	UserGroupAction_GETPAGINATION_Request,
	UserGroupAction_GETPAGINATION_Success,
	UserGroupAction_RELOAD,
	UserGroupAction_SAVE_Failure,
	UserGroupAction_SAVE_Request,
	UserGroupAction_SAVE_Success,
	UserGroupAction_SHOW_CONFIRM,
	UserGroupAction_SHOW_DETAIL,
	eUserGroupActionTypeIds,
} from './IUserGroupActionTypes';

export const userGroupActions = {
	//ALL
	getAllRequest: (): UserGroupAction_GETALL_Request => BaseAction(eUserGroupActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: UserGroupModel[]): UserGroupAction_GETALL_Success => BaseAction(eUserGroupActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): UserGroupAction_GETALL_Failure => BaseAction(eUserGroupActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: BaseSelectRequest): UserGroupAction_GETPAGINATION_Request =>
		BaseAction(eUserGroupActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationResponse<UserGroupModel>): UserGroupAction_GETPAGINATION_Success =>
		BaseAction(eUserGroupActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): UserGroupAction_GETPAGINATION_Failure =>
		BaseAction(eUserGroupActionTypeIds.GET_PAGINATION_FAILURE, error),
	//SAVE
	saveRequest: (entity: UserGroupModel): UserGroupAction_SAVE_Request => BaseAction(eUserGroupActionTypeIds.SAVE_SAVING, entity),
	saveSuccess: (message: string): UserGroupAction_SAVE_Success => BaseAction(eUserGroupActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): UserGroupAction_SAVE_Failure => BaseAction(eUserGroupActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): UserGroupAction_DELETE_Request => BaseAction(eUserGroupActionTypeIds.DELETE_SAVING, ids),
	deleteSuccess: (message: string): UserGroupAction_DELETE_Success => BaseAction(eUserGroupActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): UserGroupAction_DELETE_Failure => BaseAction(eUserGroupActionTypeIds.DELETE_FAILURE, error),
	//RELOAD
	needReload: (): UserGroupAction_RELOAD => BaseAction(eUserGroupActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): UserGroupAction_CHANGE_SELECTED_IDS => BaseAction(eUserGroupActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<UserGroupModel>): UserGroupAction_SHOW_DETAIL =>
		BaseAction(eUserGroupActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): UserGroupAction_SHOW_CONFIRM => BaseAction(eUserGroupActionTypeIds.SHOW_CONFIRM, show),
};
