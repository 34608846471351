import React, { useEffect } from 'react';
import { DxSelectBox } from '../../../components/base/DxSelectBox';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { actions } from '../../../context/stores/rootActions';

type ISelectBoxChannelProps = {
	name: string;
	value: any;
	handleOnValueChanged: (value: any, data?: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	hasValid?: boolean;
	itemRender?: (e: any) => React.ReactNode;
};

export const SelectBoxChannel = (props: ISelectBoxChannelProps) => {
	const dispatch = useDispatch();
	const { allChannels } = useSelector((state: AppState) => state.channel);
	useEffect(() => {
		if (allChannels.length === 0) dispatch(actions.channel.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DxSelectBox
			dataSource={allChannels}
			onValueChanged={(value) => {
				const detail = allChannels.find((channel) => channel.id === value);
				props.handleOnValueChanged(value, detail);
			}}
			{...props}
			valueExpr='id'
			displayExpr='name'
		/>
	);
};
