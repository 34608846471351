import { AccountInfo } from "../context/models/my-system/accounts";

export const jwtTokenHelper = {
	SET: SET_TOKEN(),
	SET_USER: SET_USER(),
	GET: GET_TOKEN(),
	CLEAR: CLEAR_TOKEN(),
	LANGCODE: GETLANG(),
};

function SET_TOKEN() {
	return (jwtToken: string) => {
		if (jwtToken && jwtToken !== '') {
			localStorage.jwtToken = jwtToken.split(' ')[0];
			localStorage.refreshToken = jwtToken.split(' ')[1];
		}
	};
}

function SET_USER() {
	return (user_info?: AccountInfo) => {
		if (user_info) {
			localStorage.USERID = user_info.id;
		}
	};
}

function GET_TOKEN() {
	return (key?: string) => {
		const value = !key ? localStorage.jwtToken : localStorage.refreshToken;
		return value;
	};
}

function CLEAR_TOKEN() {
	return () => {
		localStorage.removeItem('USERID');
		localStorage.removeItem('jwtToken');
		localStorage.removeItem('refreshToken');
	};
}

function GETLANG() {
	return (langCode?: string) => {
		let currentLang = 'vi-vn';
		if (langCode && langCode !== '') {
			currentLang = langCode;
			localStorage.langCode = langCode;
		} else {
			if (localStorage.langCode) currentLang = localStorage.langCode;
		}
		return currentLang;
	};
}
