import { useMemo } from 'react';
import { Badges, IBadgesData } from '../../../base/Badges';
import { RoleModel } from '../../../../context/models/my-system/roles/RoleModel';

type IBadgesRoleProps = {
	value: number[];
	roles_all: RoleModel[];
};

export const BadgesRole = (props: IBadgesRoleProps) => {
	const { value, roles_all } = props;
	const dataSource: IBadgesData[] = useMemo(() => {
		const datas = roles_all ? roles_all?.filter((x: any) => value.includes(x.id)) : [];
		return datas.map((data) => ({ text: `${data.name} - ${data.description}`, bgClass: data.id === 0 ? 'danger' : undefined }));
	}, [roles_all, value]);

	return <Badges dataSource={dataSource} preset='dimoutline' bgClass='primary' />;
};
