import { valid_base, valid_base_object } from '../../../base/valid_base';

export type LoginRequest = {
	user_name: string;
	password: string;
};

export const valid_LoginRequest = valid_base_object.object().shape({
	user_name: valid_base.string_required('Vui lòng nhập tài khoản'),
	password: valid_base.string_required('Vui lòng nhập mạt khẩu'),
});
