import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppState } from '../../../../context/stores/rootReducers';
import { CheckBoxList } from '../../../base';
import { actions } from '../../../../context/stores/rootActions';

type ICheckedBoxListRoleScopeProps = {
	name: string;
	selectedValue: any[];
	onSelectionChanged: (values: any[]) => void;
	label?: string;
	labelSmall?: boolean;
	hasValid?: boolean;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
};

export const CheckedBoxListRoleScope = (props: ICheckedBoxListRoleScopeProps) => {
	const dispatch = useDispatch();
	const { label, labelSmall, hasValid } = props;
	const { allRoleScopes } = useSelector((state: AppState) => state.roleScopes);
	useEffect(() => {
		if (allRoleScopes.length === 0) dispatch(actions.roleScopes.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className={`form-control-wrap`}>
					<CheckBoxList dataSource={allRoleScopes} displaySubExpr='name' displayExpr='description' keyExpr='id' {...props} />
				</div>
			</div>
		</>
	);
};
