import React, { useCallback, useState } from 'react';
import { ButtonCancel, ButtonSave, ModalBody, ModalDraggable, ModalFooter, ModalHeader } from '../../../../components/base';
import { createUUID } from '../../../../utils/createUUID';
import { CheckedBoxListRole } from '../../../../components/shared/system';
import { MenuUpdateRoleMultipleRequest } from '../../../../context/models/my-system/menus/MenuUpdateRoleRequest';

type IMenuPhanQuyenMultiModalProps = {
	isShow: boolean;
	title: string;
	menu_ids: number[];
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: MenuUpdateRoleMultipleRequest) => void;
};

const MenuPhanQuyenMultiModal = React.memo((props: IMenuPhanQuyenMultiModalProps) => {
	const { menu_ids, isShow, title, onSubmit, onClose, isSaving } = props;

	const [roleSelected, setRoleSelected] = useState<number[]>([]);

	const handleSubmit = useCallback(() => {
		onSubmit({ menu_ids: menu_ids, role_ids: roleSelected });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleSelected, menu_ids]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='md' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<CheckedBoxListRole
						name={createUUID()}
						label='Quyền hạn'
						hasValid={true}
						onSelectionChanged={(value: any[]) => {
							setRoleSelected(value || []);
						}}
						selectedValue={roleSelected}
					/>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving || roleSelected.length <= 0}
						onClick={() => handleSubmit()}
						text={`Phân quyền`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
});

export default MenuPhanQuyenMultiModal;
