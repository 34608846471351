import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './AppRoutes';
import { Loading } from './components/base';
import { eBaseActionStatus } from './context/base/eBaseActionStatus';
import { actions } from './context/stores/rootActions';
import { AppState } from './context/stores/rootReducers';
import { AdminLayout, PublicLayout } from './layouts';
import { NotFound } from './pages/my-system/error';
import LoginPage from './pages/my-system/login';
import AuthProvider from './utils/AuthProvider';


const App = () => {
	const dispatch = useDispatch();
	const { accountStatus, accountInfo } = useSelector((state: AppState) => state.account);
	const navigate = useNavigate();

	// useEffect(() => {
	// 	if (!accountInfo || accountInfo === null) navigate('/login', { replace: false });
	// 	//eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [accountInfo]);

	// call this function to sign out logged in user

	const logout = () => {
		dispatch(actions.account.logoutRequest());
		navigate('/login', { replace: false });
	};

	useEffect(() => {
		if (accountStatus === eBaseActionStatus.idle) dispatch(actions.account.getAccountRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountStatus]);

	useEffect(() => {
		if (accountStatus === eBaseActionStatus.complete && (!accountInfo || accountInfo == null)) navigate('/login', { replace: false });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountStatus, accountInfo]);

	if (accountStatus !== eBaseActionStatus.complete) return <Loading />;

	return (
		<>
			<AuthProvider account={accountInfo} logOutAction={logout}>
				<Routes>
					<Route element={<AdminLayout />}>
						{AppRoutes &&
							AppRoutes.map(({ path, Component }, index) => {
								return <Route key={index} path={path} element={<Component />} />;
							})}
						<Route path='*' element={<NotFound />} />
					</Route>
					<Route element={<PublicLayout />}>
						<Route path='/login' element={<LoginPage />} />
					</Route>
				</Routes>
			</AuthProvider>
			<ToastContainer
				position='bottom-center'
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover
			/>
		</>
	);
};

export default App;
