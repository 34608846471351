import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BasePaginationResponse, defaultBasePagination } from '../../base/BasePaginationResponse';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { dfArticleActionConfirmRequest } from '../../models/articles/ArticleActionConfirmRequest';
import { ArticleItemResponse } from '../../models/articles/ArticleModel';
import { ArticleActionTypes, eArticleActionTypeIds } from './IArticleActionsTypes';
import { IArticleState } from './IArticleState';

const initPaginationResponse: BasePaginationResponse<ArticleItemResponse> = { listDatas: [], pagination: defaultBasePagination };
const initialState: IArticleState = {
	status: eBaseActionStatus.idle,
	selectedIds: [],
	showConfirm: dfArticleActionConfirmRequest,
	paginationResponse: initPaginationResponse,
	showDetail: { isShow: false },
	detailResponse: undefined,
	detailEditResponse: undefined,
	relatedResponse: [],
	listByTypeNews: []
};
const articleReducer = (state: IArticleState = initialState, action: ArticleActionTypes): IArticleState => {
	switch (action.type) {
		case eArticleActionTypeIds.GET_PAGINATION_REQUEST:
		case eArticleActionTypeIds.GET_BY_ACTION_REQUEST:
		case eArticleActionTypeIds.GET_RELATED_REQUEST:
		case eArticleActionTypeIds.GET_TYPENEWS_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_BY_ACTION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_BY_ACTION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_BY_OWNER_ACTION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_BY_OWNER_ACTION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_BY_OWNER_ACTION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_PUBLISHED_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_PUBLISHED_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_PUBLISHED_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_RELATED_SUCCESS:
			return {
				...state,
				relatedResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_RELATED_FAILURE:
			return {
				...state,
				relatedResponse: [],
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_TYPENEWS_SUCCESS:
			return {
				...state,
				listByTypeNews: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_TYPENEWS_FAILURE:
			return {
				...state,
				listByTypeNews: [],
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eArticleActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eArticleActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eArticleActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_DETAIL_REQUEST:
		case eArticleActionTypeIds.GET_DETAIL_EDIT_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.CHANGE_STATUS_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Cập nhật thành công.');
			return {
				...state,
				showDetail: { isShow: false },
				showConfirm: dfArticleActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_DETAIL_SUCCESS:
			return {
				...state,
				detailResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_DETAIL_EDIT_SUCCESS:
			return {
				...state,
				detailEditResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa thành công');
			return {
				...state,
				selectedIds: [],
				showDetail: { isShow: false },
				showConfirm: dfArticleActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.CHANGE_STATUS_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Thay đổi thành công');
			return {
				...state,
				selectedIds: [],
				showDetail: { isShow: false },
				showConfirm: dfArticleActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				showConfirm: dfArticleActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.GET_DETAIL_FAILURE:
		case eArticleActionTypeIds.GET_DETAIL_EDIT_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				detailResponse: undefined,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showDetail: { isShow: false },
				showConfirm: dfArticleActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.CHANGE_STATUS_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showDetail: { isShow: false },
				showConfirm: dfArticleActionConfirmRequest,
				status: BaseGetActionStatus(action),
			};
		case eArticleActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default articleReducer;
