import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';

export function useLookupRoles() {
	const dispatch = useDispatch();
	const { allRoles } = useSelector((state: AppState) => state.roles);
	useEffect(() => {
		if (allRoles.length <= 0) dispatch(actions.roles.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { roles_lookup: allRoles };
}
