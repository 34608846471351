import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../../api/rootApi';
import { BaseResponse } from '../../../base/BaseResponse';
import {
	UserGroupAction_DELETE_Request,
	UserGroupAction_GETALL_Request,
	UserGroupAction_GETPAGINATION_Request,
	UserGroupAction_SAVE_Request,
	eUserGroupActionTypeIds,
} from './IUserGroupActionTypes';
import { UserGroupModel } from '../../../models/my-system/users-group/UserGroupModel';
import { actions } from '../../rootActions';

const _sagaApi = rootApi.userGroup;

function* onLoadAllUserGroup(action: UserGroupAction_GETALL_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listUserGroup = res.result as UserGroupModel[];
			yield put(actions.usersGroup.getAllSuccess(listUserGroup));
		} else {
			yield put(actions.usersGroup.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.usersGroup.getAllFailure(error || 'Có lỗi'));
	}
}

function* onLoadPaginationUserGroup(action: UserGroupAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listUserGroup = res.result as UserGroupModel[];
			yield put(actions.usersGroup.getPaginationSuccess({ listDatas: listUserGroup, pagination: res.pagination }));
		} else {
			yield put(actions.usersGroup.getPaginationFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.usersGroup.getPaginationFailure(error || 'Có lỗi'));
	}
}

function* onSaveUserGroup(action: UserGroupAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.usersGroup.saveSuccess(res.message));
				yield put(actions.usersGroup.needReload());
			} else {
				yield put(actions.usersGroup.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.usersGroup.saveSuccess(res.message));
				yield put(actions.usersGroup.needReload());
			} else {
				yield put(actions.usersGroup.saveFailure(res.message));
			}
		}
	} catch (error: any) {
		yield put(actions.usersGroup.saveFailure(error || 'Có lỗi'));
	}
}

function* onDeleteUserGroup(action: UserGroupAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.usersGroup.deleteSuccess(res.message));
			yield put(actions.usersGroup.needReload());
		} else {
			yield put(actions.usersGroup.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.usersGroup.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllUserGroup() {
	yield takeEvery(eUserGroupActionTypeIds.GET_ALL_REQUEST, onLoadAllUserGroup);
}

function* watchOnLoadPaginationUserGroup() {
	yield takeEvery(eUserGroupActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationUserGroup);
}

function* watchOnSaveUserGroup() {
	yield takeEvery(eUserGroupActionTypeIds.SAVE_SAVING, onSaveUserGroup);
}

function* watchOnDeleteUserGroup() {
	yield takeEvery(eUserGroupActionTypeIds.DELETE_SAVING, onDeleteUserGroup);
}

function* usersGroupSaga() {
	yield all([fork(watchOnLoadAllUserGroup)]);
	yield all([fork(watchOnLoadPaginationUserGroup)]);
	yield all([fork(watchOnSaveUserGroup)]);
	yield all([fork(watchOnDeleteUserGroup)]);
}

export default usersGroupSaga;
