import { IActionTypeBase } from '../../../base/IBaseActionTypes';

export enum eCommonActionTypeIds {
	CHANGE_MENU_MODE = 'CHANGE_KHOI',
	HEADER_RESET_SELECTED = 'HEADER_RESET_SELECTED',
}

export interface CommonAction_CHANGE_MENU_MODE extends IActionTypeBase<eCommonActionTypeIds.CHANGE_MENU_MODE, string> {}
export interface CommonAction_HEADER_RESET_SELECTED extends IActionTypeBase<eCommonActionTypeIds.HEADER_RESET_SELECTED, undefined> {}

export type CommonActionTypes = CommonAction_CHANGE_MENU_MODE | CommonAction_HEADER_RESET_SELECTED;
