import React from 'react';

interface ISwitchBoxProps {
	name: string;
	value: boolean;
	onValueChanged: (value: boolean) => void;
	label?: string;
	error?: string;
	sizeClass?: 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
}

export const SwitchBox = (props: ISwitchBoxProps) => {
	const { value, error, name, label, sizeClass, isDisabled, helpBlock, labelSmall, hasValid, onValueChanged } = props;
	const showError = error ? true : false;
	return (
		<>
			<div className={`custom-control custom-switch mb-2 custom-control-${sizeClass || 'sm'} ${showError ? 'error' : ''}`}>
				<input
					className='custom-control-input'
					id={`switch-${name}`}
					name={name}
					type='checkbox'
					value={'true'}
					checked={value}
					disabled={isDisabled}
					onChange={(e) => onValueChanged(e.target.checked)}
				/>
				<label className={`custom-control-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={`switch-${name}`}>
					{label} {hasValid && <em className='text-danger'>(*)</em>}
				</label>
			</div>
			{showError && <span className='form-note invalid'>{error}</span>}
			{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
		</>
	);
};
