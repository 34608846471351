import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BaseForm, ButtonCancel, ButtonSave, ModalBody, ModalDraggable, ModalFooter, ModalHeader, TextBox } from '../../../components/base';
import { RoyaltyModel, dfRoyaltyModel, valid_Royalty } from '../../../context/models/royalty/RoyaltyModel';

type IRoyaltyDetailProps = {
	isShow: boolean;
	title: string;
	detailModel?: RoyaltyModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: RoyaltyModel) => void;
};

const RoyaltyDetail = (props: IRoyaltyDetailProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<RoyaltyModel>(dfRoyaltyModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<RoyaltyModel>({
		resolver: yupResolver(valid_Royalty()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: RoyaltyModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({
				...prev,
				...detailModel,
			}));
		}
	}, [detailModel]);

	return (
		<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
			<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
			<ModalBody>
				<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
					<TextBox
						name={'name'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, name: value }));
						}}
						value={initialValues.name}
						hasValid={true}
						label='Tên loại nhuận bút'
					/>
					<TextBox
						name={'description'}
						error={errors.name?.message}
						onValueChanged={(value) => setInitialValues((prev) => ({ ...prev, description: value }))}
						value={initialValues.description}
						hasValid={true}
						label='Mô tả'
					/>
					<TextBox
						name={'sort_order'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, sort_order: value }));
						}}
						value={initialValues.sort_order.toString()}
						hasValid={true}
						label='Thứ tự'
					/>
					<TextBox
						name={'money'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, money: value }));
						}}
						value={initialValues.money.toString().replace(' ₫', '').replace('.', '')}
						hasValid={true}
						label='Giá tiền'
					/>
					<TextBox
						name={'min_view'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, minView: value }));
						}}
						value={initialValues.minView.toString().replace(' ₫', '').replace('.', '')}
						hasValid={true}
						label='Lượt xem tối thiểu'
					/>
				</BaseForm>
			</ModalBody>
			<ModalFooter>
				<ButtonCancel onClick={onClose} isDisabled={isSaving} />
				<ButtonSave
					type='button'
					isLoading={isSaving}
					isDisabled={isSaving}
					onClick={handleSubmit(onSubmitHandler)}
					text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
				/>
			</ModalFooter>
		</ModalDraggable>
	);
};

export default RoyaltyDetail;
