import { valid_base, valid_base_object } from '../../base/valid_base';

export type ArticleTypeModel = {
	id: number;
	name: string;
	description: string;
	sort_order: string;
	money: string;
};

export const dfArticleTypeModel: ArticleTypeModel = {
	id: 0,
	name: '',
	description: '',
	sort_order: '',
	money: '',
};

export const valid_ArticleType = () =>
	valid_base_object.object<ArticleTypeModel>().shape({
		id: valid_base.number_required(),
		name: valid_base.string_required(),
		description: valid_base.string_required(),
		sort_order: valid_base.string_required(),
		money: valid_base.string_required(),
	});
