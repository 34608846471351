import { BasePaginationResponse } from '../../../base/BasePaginationResponse';
import { BaseShowDetail } from '../../../base/BaseShowDetail';
import { IActionTypeBase } from '../../../base/IBaseActionTypes';
import { RoleModel } from '../../../models/my-system/roles/RoleModel';
import { RoleSelectRequest } from '../../../models/my-system/roles/RoleSelectRequest';

export enum eRolesActionTypeIds {
	GET_ALL_REQUEST = 'Roles_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'Roles_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'Roles_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'Roles_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'Roles_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'Roles_GET_PAGINATION_FAILURE',

	SAVE_SAVING = 'Roles_SAVE_SAVING',
	SAVE_SUCCESS = 'Roles_SAVE_SUCCESS',
	SAVE_FAILURE = 'Roles_SAVE_FAILURE',

	DELETE_SAVING = 'Roles_DELETE_SAVING',
	DELETE_SUCCESS = 'Roles_DELETE_SUCCESS',
	DELETE_FAILURE = 'Roles_DELETE_FAILURE',

	NEED_RELOAD = 'Roles_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'Roles_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'Roles_SHOW_DETAIL',
	CLOSE_DETAIL = 'Roles_CLOSE_DETAIL',
	SHOW_CONFIRM = 'Roles_SHOW_CONFIRM',
	CLOSE_CONFIRM = 'Roles_CLOSE_CONFIRM',
}

export interface RolesAction_GETALL_Request extends IActionTypeBase<eRolesActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface RolesAction_GETALL_Success extends IActionTypeBase<eRolesActionTypeIds.GET_ALL_SUCCESS, RoleModel[]> {}
export interface RolesAction_GETALL_Failure extends IActionTypeBase<eRolesActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface RolesAction_GETPAGINATION_Request extends IActionTypeBase<eRolesActionTypeIds.GET_PAGINATION_REQUEST, RoleSelectRequest> {}
export interface RolesAction_GETPAGINATION_Success
	extends IActionTypeBase<eRolesActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationResponse<RoleModel>> {}
export interface RolesAction_GETPAGINATION_Failure extends IActionTypeBase<eRolesActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface RolesAction_SAVE_Request extends IActionTypeBase<eRolesActionTypeIds.SAVE_SAVING, RoleModel> {}
export interface RolesAction_SAVE_Success extends IActionTypeBase<eRolesActionTypeIds.SAVE_SUCCESS, string> {}
export interface RolesAction_SAVE_Failure extends IActionTypeBase<eRolesActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface RolesAction_DELETE_Request extends IActionTypeBase<eRolesActionTypeIds.DELETE_SAVING, number[]> {}
export interface RolesAction_DELETE_Success extends IActionTypeBase<eRolesActionTypeIds.DELETE_SUCCESS, string> {}
export interface RolesAction_DELETE_Failure extends IActionTypeBase<eRolesActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface RolesAction_RELOAD extends IActionTypeBase<eRolesActionTypeIds.NEED_RELOAD, undefined> {}

export interface RolesAction_SHOW_DETAIL extends IActionTypeBase<eRolesActionTypeIds.SHOW_DETAIL, BaseShowDetail<RoleModel>> {}
export interface RolesAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eRolesActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface RolesAction_SHOW_CONFIRM extends IActionTypeBase<eRolesActionTypeIds.SHOW_CONFIRM, boolean> {}

export type RolesActionTypes =
	| RolesAction_GETALL_Request
	| RolesAction_GETALL_Success
	| RolesAction_GETALL_Failure
	| RolesAction_GETPAGINATION_Request
	| RolesAction_GETPAGINATION_Success
	| RolesAction_GETPAGINATION_Failure
	| RolesAction_SAVE_Request
	| RolesAction_SAVE_Success
	| RolesAction_SAVE_Failure
	| RolesAction_DELETE_Request
	| RolesAction_DELETE_Success
	| RolesAction_DELETE_Failure
	| RolesAction_RELOAD
	| RolesAction_SHOW_DETAIL
	| RolesAction_CHANGE_SELECTED_IDS
	| RolesAction_SHOW_CONFIRM;
