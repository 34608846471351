import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { BaseForm, ButtonCancel, ButtonSave, ModalBody, ModalDraggable, ModalFooter, ModalHeader, PasswordBox } from '../../../base';
import { ChangePasswordRequest } from '../../../../context/models/my-system/accounts';
import { valid_base, valid_base_object } from '../../../../context/base/valid_base';

interface Props {
	changePassword: (data: ChangePasswordRequest) => void;
	isShow: boolean;
	onClose: () => void;
	isSaving: boolean;
}

const ChangePasswordModal = (props: Props) => {
	const { isShow, changePassword, onClose, isSaving } = props;

	const [initialValues, setInitialValues] = useState<ChangePasswordRequest>({
		password_confirmed: '',
		password_new: '',
	});

	// form validation rules
	const validationSchema = valid_base_object.object().shape({
		password_new: valid_base.password(),
		password_confirmed: valid_base.password_confirmed('password_new'),
	});
	const formActions = useForm({
		resolver: yupResolver(validationSchema),
		defaultValues: initialValues,
	});

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = formActions;

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	const onSubmitHandler = (values: ChangePasswordRequest) => {
		setInitialValues((prev) => ({ ...prev, ...values }));
		changePassword(values);
	};

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={'Thay đổi mật khẩu'} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<PasswordBox
							name={'password_new'}
							error={errors.password_new?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, password_new: value }));
							}}
							value={initialValues.password_new || ''}
							hasValid={true}
							label='Mật khẩu mới'
						/>
						<PasswordBox
							name={'password_confirmed'}
							error={errors.password_confirmed?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, password_confirmed: value }));
							}}
							value={initialValues.password_confirmed || ''}
							hasValid={true}
							label='Xác nhận mật khẩu mới'
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`Đổi mật khẩu`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default ChangePasswordModal;
