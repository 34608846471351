import React, { useEffect, useState } from 'react';

export type ISearchBoxProps = {
	name: string;
	value: string;
	onValueChanged: (value: string) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	classWrapper?: string;
};

export const SearchBox = (props: ISearchBoxProps) => {
	const [searchValue, setSearcheValue] = useState('');
	const { value, error, name, label, sizeClass, isDisabled, helpBlock, labelSmall, hasValid, placeholder, onValueChanged, classWrapper } = props;
	const showError = error ? true : false;

	useEffect(() => {
		setSearcheValue(value || '');
	}, [value]);

	return (
		<>
			<div className={`form-group ${classWrapper || ''}`}>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<button
						type='button'
						tabIndex={1}
						className={`btn btn-search btn-primary form-icon form-icon-right icon ni ni-search ${sizeClass || 'sm'}`}
						onClick={() => onValueChanged(searchValue)}
					></button>
					<input
						id={name}
						name={name}
						type={'search'}
						aria-invalid={error ? 'true' : 'false'}
						value={searchValue}
						disabled={isDisabled}
						placeholder={placeholder ? placeholder : 'Tìm kiếm ...'}
						tabIndex={0}
						onChange={(e) => setSearcheValue(e.target.value || '')}
						className={`form-control pe-5 form-control-${sizeClass || 'sm'} ${showError ? 'error' : ''}`}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
