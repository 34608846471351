import { BaseShowDetail } from '../../../base/BaseShowDetail';
import { BaseAction } from '../../../base/IBaseActionRespone';
import { MenuItemResponse } from '../../../models/my-system/menus/MenuItemResponse';
import {
	MenuRemoveRoleRequest,
	MenuUpdateRoleMultipleRequest,
	MenuUpdateRoleRequest,
} from '../../../models/my-system/menus/MenuUpdateRoleRequest';
import {
	MenusAction_CHANGE_ROLE_Failure,
	MenusAction_CHANGE_ROLE_MULTI_Failure,
	MenusAction_CHANGE_ROLE_MULTI_Request,
	MenusAction_CHANGE_ROLE_MULTI_Success,
	MenusAction_CHANGE_ROLE_Request,
	MenusAction_CHANGE_ROLE_Success,
	MenusAction_CHANGE_SELECTED_IDS,
	MenusAction_GETALL_Failure,
	MenusAction_GETALL_Request,
	MenusAction_GETALL_Success,
	MenusAction_RELOAD,
	MenusAction_REMOVE_ROLE_Failure,
	MenusAction_REMOVE_ROLE_Request,
	MenusAction_REMOVE_ROLE_Success,
	MenusAction_SHOW_CHANGE_ROLE,
	MenusAction_SHOW_CHANGE_ROLE_MULTI,
	MenusAction_SHOW_CONFIRM,
	eMenusActionTypeIds,
} from './IMenuActionTypes';

export const menusActions = {
	//ALL
	getAllRequest: (): MenusAction_GETALL_Request => BaseAction(eMenusActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: MenuItemResponse[]): MenusAction_GETALL_Success => BaseAction(eMenusActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): MenusAction_GETALL_Failure => BaseAction(eMenusActionTypeIds.GET_ALL_FAILURE, error),
	//CHANGE ROLE
	showChangeRole: (detail: BaseShowDetail<MenuItemResponse>): MenusAction_SHOW_CHANGE_ROLE =>
		BaseAction(eMenusActionTypeIds.SHOW_CHANGE_ROLE, detail),
	changeRoleRequest: (entity: MenuUpdateRoleRequest): MenusAction_CHANGE_ROLE_Request =>
		BaseAction(eMenusActionTypeIds.CHANGE_ROLE_REQUEST, entity),
	changeRoleSuccess: (message: string): MenusAction_CHANGE_ROLE_Success => BaseAction(eMenusActionTypeIds.CHANGE_ROLE_SUCCESS, message),
	changeRoleFailure: (error: Error | string): MenusAction_CHANGE_ROLE_Failure => BaseAction(eMenusActionTypeIds.CHANGE_ROLE_FAILURE, error),
	//CHANGE ROLEMULTI
	showChangeRoleMulti: (show: boolean): MenusAction_SHOW_CHANGE_ROLE_MULTI => BaseAction(eMenusActionTypeIds.SHOW_CHANGE_ROLE_MULTI, show),
	changeRoleMultiRequest: (entity: MenuUpdateRoleMultipleRequest): MenusAction_CHANGE_ROLE_MULTI_Request =>
		BaseAction(eMenusActionTypeIds.CHANGE_ROLE_MULTI_REQUEST, entity),
	changeRoleMultiSuccess: (message: string): MenusAction_CHANGE_ROLE_MULTI_Success =>
		BaseAction(eMenusActionTypeIds.CHANGE_ROLE_MULTI_SUCCESS, message),
	changeRoleMultiFailure: (error: Error | string): MenusAction_CHANGE_ROLE_MULTI_Failure =>
		BaseAction(eMenusActionTypeIds.CHANGE_ROLE_MULTI_FAILURE, error),
	//REMOVE ROLE
	removeRoleRequest: (entity: MenuRemoveRoleRequest): MenusAction_REMOVE_ROLE_Request =>
		BaseAction(eMenusActionTypeIds.REMOVE_ROLE_REQUEST, entity),
	removeRoleSuccess: (message: string): MenusAction_REMOVE_ROLE_Success => BaseAction(eMenusActionTypeIds.REMOVE_ROLE_SUCCESS, message),
	removeRoleFailure: (error: Error | string): MenusAction_REMOVE_ROLE_Failure => BaseAction(eMenusActionTypeIds.REMOVE_ROLE_FAILURE, error),

	changeSelectedIds: (ids: number[]): MenusAction_CHANGE_SELECTED_IDS => BaseAction(eMenusActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showConfirm: (show: boolean): MenusAction_SHOW_CONFIRM => BaseAction(eMenusActionTypeIds.SHOW_CONFIRM, show),
	//RELOAD
	needReload: (): MenusAction_RELOAD => BaseAction(eMenusActionTypeIds.NEED_RELOAD, undefined),
};
