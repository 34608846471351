/* eslint-disable react-refresh/only-export-components */
import { connect } from 'react-redux';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { AppState } from '../../../context/stores/rootReducers';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { ArticleTypeModel } from '../../../context/models/articles-type/ArticleTypeModel';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { actions } from '../../../context/stores/rootActions';
import { Dispatch } from 'redux';
import { ArticleTypeActionTypes } from '../../../context/stores/articles-type/IArticleTypeActionsTypes';
import React, { useEffect, useMemo } from 'react';
import DxTable, { DxTableColumn } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import ArticleTypeDetail from './ArticleTypeDetail';
import { toNumberWithPeriod } from '../../../utils/numberHelper';

type IArticleTypePageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allArticleTypes: ArticleTypeModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<ArticleTypeModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: ArticleTypeModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<ArticleTypeModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Loại tin bài`,
		status: state.articleType.status,
		allArticleTypes: state.articleType.allArticleTypes,
		selectedIds: state.articleType.selectedIds,
		showDetail: state.articleType.showDetail,
		showConfirm: state.articleType.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<ArticleTypeActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.articleType.getAllRequest());
	},
	handleSaveData: (data: ArticleTypeModel) => {
		dispatch(actions.articleType.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.articleType.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.articleType.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<ArticleTypeModel>) => {
		dispatch(actions.articleType.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.articleType.showConfirm(isShow));
	},
});

const ArticleTypePage = (props: IArticleTypePageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allArticleTypes,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const dataSource = useMemo(() => {
		return allArticleTypes.map((type) => ({
			...type,
			money: `${toNumberWithPeriod(parseInt(type.money, 10))} ₫`,
		}));
	}, [allArticleTypes]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên`} width={200}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='sort_order' width={50} caption={`Thứ tự`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='money' width={150} caption={`Giá tiền`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='description' caption={`Mô tả`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allArticleTypes]);

	return (
		<React.Fragment>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={dataSource}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<ArticleTypeDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(Object.assign(data, { money: data.money.replace(' ₫', '').replace('.', '') }))}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</React.Fragment>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTypePage);
