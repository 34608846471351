import React, { useMemo } from 'react';
import SelectBox from 'devextreme-react/select-box';
import './SelectBox.css';

export type ISelectBoxProps = {
	name: string;
	dataSource: any;
	value: any;
	onValueChanged: (value: any) => void;
	valueExpr: string;
	displayExpr: string;
	displaySubExpr?: string;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	hasValid?: boolean;
	itemRender?: (e: any) => React.ReactNode;
	width?: string | number;
	grouped?: boolean;
	isViewMode?: 'dot_bottom' | 'line_bottom';
	classWrapper?: string;
};

export const DxSelectBox = (props: ISelectBoxProps) => {
	const {
		value,
		error,
		name,
		label,
		sizeClass,
		isDisabled,
		helpBlock,
		labelSmall,
		hasValid,
		dataSource,
		onValueChanged,
		valueExpr,
		displayExpr,
		isClearable,
		placeholder,
		itemRender,
		width,
		grouped,
		isViewMode,
		classWrapper
	} = props;
	const showError = error ? true : false;
	const classBuild = useMemo(() => {
		let classBuild = `select-box form-control-${sizeClass || 'xs'}`;
		if (showError) classBuild = `${classBuild} error`;
		if (isViewMode) classBuild = `${classBuild} ${isViewMode}`;
		return classBuild;
	}, [sizeClass, showError, isViewMode]);
	return (
		<>
			<div className={`form-group ${classWrapper || ''}`}>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<SelectBox
						className={classBuild}
						id={name}
						valueExpr={valueExpr}
						displayExpr={displayExpr}
						dataSource={dataSource}
						disabled={isDisabled || (isViewMode && isViewMode.length > 0)}
						showClearButton={isClearable}
						searchEnabled={true}
						width={width}
						name={name}
						value={value}
						onValueChanged={(e) => {
							if (e.event) {
								onValueChanged(e.value);
							}
						}}
						noDataText='Không có dữ liệu'
						placeholder={placeholder}
						itemRender={itemRender}
						grouped={grouped || false}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
