import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { RoyaltyModel } from '../../models/royalty/RoyaltyModel';
import {
	RoyaltyAction_CHANGE_SELECTED_IDS,
	RoyaltyAction_DELETE_Request,
	RoyaltyAction_DELETE_Success,
	RoyaltyAction_GETALL_Failure,
	RoyaltyAction_GETALL_Request,
	RoyaltyAction_GETALL_Success,
	RoyaltyAction_GETPAGINATION_Failure,
	RoyaltyAction_GETPAGINATION_Request,
	RoyaltyAction_GETPAGINATION_Success,
	RoyaltyAction_RELOAD,
	RoyaltyAction_SAVE_Request,
	RoyaltyAction_SAVE_Success,
	RoyaltyAction_SHOW_CONFIRM,
	RoyaltyAction_SHOW_DETAIL,
	eRoyaltyActionTypeIds,
} from './IRoyaltyActionsTypes';

export const royaltyActions = {
	getAllRequest: (): RoyaltyAction_GETALL_Request => BaseAction(eRoyaltyActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: RoyaltyModel[]): RoyaltyAction_GETALL_Success => BaseAction(eRoyaltyActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (err: Error | string): RoyaltyAction_GETALL_Failure => BaseAction(eRoyaltyActionTypeIds.GET_ALL_FAILURE, err),

	getPaginationRequest: (req: BaseSelectRequest): RoyaltyAction_GETPAGINATION_Request =>
		BaseAction(eRoyaltyActionTypeIds.GET_PAGINATION_REQUEST, req),
	getPaginationSuccess: (data: BasePaginationResponse<RoyaltyModel>): RoyaltyAction_GETPAGINATION_Success =>
		BaseAction(eRoyaltyActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (err: Error | string): RoyaltyAction_GETPAGINATION_Failure =>
		BaseAction(eRoyaltyActionTypeIds.GET_PAGINATION_FAILURE, err),

	saveRequest: (payload: RoyaltyModel): RoyaltyAction_SAVE_Request => BaseAction(eRoyaltyActionTypeIds.SAVE_REQUEST, payload),
	saveSuccess: (msg: string): RoyaltyAction_SAVE_Success => BaseAction(eRoyaltyActionTypeIds.SAVE_SUCCESS, msg),
	saveFailure: (err: Error | string) => BaseAction(eRoyaltyActionTypeIds.SAVE_FAILURE, err),

	deleteRequest: (payload: number[]): RoyaltyAction_DELETE_Request => BaseAction(eRoyaltyActionTypeIds.DELETE_REQUEST, payload),
	deleteSuccess: (msg: string): RoyaltyAction_DELETE_Success => BaseAction(eRoyaltyActionTypeIds.DELETE_SUCCESS, msg),
	deleteFailure: (err: Error | string) => BaseAction(eRoyaltyActionTypeIds.DELETE_FAILURE, err),

	reload: (): RoyaltyAction_RELOAD => BaseAction(eRoyaltyActionTypeIds.RELOAD, undefined),
	showDetail: (detail: BaseShowDetail<RoyaltyModel>): RoyaltyAction_SHOW_DETAIL => BaseAction(eRoyaltyActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (isShow: boolean): RoyaltyAction_SHOW_CONFIRM => BaseAction(eRoyaltyActionTypeIds.SHOW_CONFIRM, isShow),
	changeSelectedIds: (payload: number[]): RoyaltyAction_CHANGE_SELECTED_IDS =>
		BaseAction(eRoyaltyActionTypeIds.CHANGE_SELECTED_IDS, payload),
};
