import React, { useMemo } from 'react';

interface INumberBoxProps {
	name: string;
	value: number;
	onValueChanged: (value: number) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	isViewMode?: 'dot_bottom' | 'line_bottom';
}

export const NumberBox = (props: INumberBoxProps) => {
	const { value, error, name, label, sizeClass, isDisabled, helpBlock, labelSmall, hasValid, placeholder, onValueChanged, isViewMode } =
		props;
	const showError = error ? true : false;
	const classBuild = useMemo(() => {
		let classBuild = `form-control form-control-${sizeClass || 'sm'} textarea-${sizeClass || 'sm'}`;
		if (showError) classBuild = `${classBuild} error`;
		if (isViewMode) classBuild = `${classBuild} ${isViewMode}`;
		return classBuild;
	}, [sizeClass, showError, isViewMode]);
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<input
						id={name}
						type={'number'}
						name={name}
						value={value}
						disabled={isDisabled || (isViewMode && isViewMode.length > 0)}
						placeholder={placeholder}
						onChange={(e) => onValueChanged(parseInt(e.target.value || '0'))}
						className={classBuild}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
