import { BaseGetActionStatus, eBaseActionStatus } from '../../../base/eBaseActionStatus';
import { NotifyHelper } from '../../../../utils/NotifyHelper';
import { IMenusState } from './IMenuState';
import { MenusActionTypes, eMenusActionTypeIds } from './IMenuActionTypes';

const initialState: IMenusState = {
	status: eBaseActionStatus.idle,
	menus_all: [],
	showChangeRole: { isShow: false },
	showChangeRoleMulti: false,
	selectedIds: [],
	showConfirm: false,
};
const menusReducer = (state: IMenusState = initialState, action: MenusActionTypes): IMenusState => {
	switch (action.type) {
		case eMenusActionTypeIds.GET_ALL_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				menus_all: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				menus_all: [],
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eMenusActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eMenusActionTypeIds.SHOW_CHANGE_ROLE:
			return {
				...state,
				showChangeRole: action.payload,
			};
		case eMenusActionTypeIds.SHOW_CHANGE_ROLE_MULTI:
			return {
				...state,
				showChangeRoleMulti: action.payload,
			};
		case eMenusActionTypeIds.CHANGE_ROLE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.CHANGE_ROLE_MULTI_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.REMOVE_ROLE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.CHANGE_ROLE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Thành công !!!');
			return {
				...state,
				showChangeRole: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.CHANGE_ROLE_MULTI_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Thành công !!!');
			return {
				...state,
				showChangeRoleMulti: false,
				selectedIds: [],
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.REMOVE_ROLE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Thành công !!!');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.CHANGE_ROLE_FAILURE:
		case eMenusActionTypeIds.CHANGE_ROLE_MULTI_FAILURE:
		case eMenusActionTypeIds.REMOVE_ROLE_FAILURE:
			NotifyHelper.Error(action.payload.toString() || 'Không thành công !!!');
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eMenusActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default menusReducer;
