import { NotifyHelper } from '../../../../utils/NotifyHelper';
import { BasePaginationResponse, defaultBasePagination } from '../../../base/BasePaginationResponse';
import { BaseGetActionStatus, eBaseActionStatus } from '../../../base/eBaseActionStatus';
import { UserGroupModel } from '../../../models/my-system/users-group/UserGroupModel';
import { UserGroupActionTypes, eUserGroupActionTypeIds } from './IUserGroupActionTypes';
import { IUserGroupState } from './IUserGroupState';

const initPaginationResponse: BasePaginationResponse<UserGroupModel> = { listDatas: [], pagination: defaultBasePagination };
const initialState: IUserGroupState = {
	status: eBaseActionStatus.idle,
	users_group_all: [],
	users_group_pagination: initPaginationResponse,
	selectedIds: [],
	showConfirm: false,
	showDetail: { isShow: false },
};
const usersGroupReducer = (state: IUserGroupState = initialState, action: UserGroupActionTypes): IUserGroupState => {
	switch (action.type) {
		case eUserGroupActionTypeIds.GET_ALL_REQUEST:
		case eUserGroupActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				users_group_all: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				users_group_pagination: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				users_group_all: [],
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				users_group_pagination: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eUserGroupActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eUserGroupActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eUserGroupActionTypeIds.SAVE_SAVING:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.DELETE_SAVING:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload ?? 'Lưu tác nhân thành công.');
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa tác nhân thành công');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eUserGroupActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default usersGroupReducer;
