import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { useEffect } from 'react';
import { actions } from '../../../context/stores/rootActions';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';

export function useLookupArticleCategory() {
	const dispatch = useDispatch();
	const { status, allArticleCategorys } = useSelector((state: AppState) => state.articleCategory);
	useEffect(() => {
		if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload) dispatch(actions.articleCategory.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { article_category_all: allArticleCategorys };
}
