import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { actions } from '../../../context/stores/rootActions';

export function useLookupUserGroup() {
	const dispatch = useDispatch();
	const { users_group_all } = useSelector((state: AppState) => state.usersGroup);
	useEffect(() => {
		if (users_group_all.length === 0) dispatch(actions.usersGroup.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { users_group_lookup: users_group_all };
}
