import React from 'react';
import './PreviewImage.css';

type IPreviewImageProps = {
	image?: string;
	onClose: () => void;
};

export const PreviewImage = (props: IPreviewImageProps) => {
	const { image, onClose } = props;
	return (
		<>
			{image && (
				<div className='overlay dismiss' onClick={onClose}>
					<img src={image} alt={image} />
					<button type='button' className='btn btn-link dismiss-btn' onClick={() => onClose}>
						X
					</button>
					<p className='detail text-center ff-italic'>{image}</p>
				</div>
			)}
		</>
	);
};
