export type BasePaginationResponse<T> = {
	listDatas: T[];
	pagination?: BasePagination;
};
export type BasePagination = {
	current_page: number;
	page_size: number;
	total_records: number;
	total_pages: number;
};
export const defaultBasePagination: BasePagination = {
	current_page: 1,
	page_size: 20,
	total_pages: 0,
	total_records: 0,
};
