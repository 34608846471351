import React, { useMemo } from 'react';

type ITextBoxProps = {
	name: string;
	value: string;
	onValueChanged: (value: string) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	type?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	className?: string;
	classWrapper?: string;
	isViewMode?: 'dot_bottom' | 'line_bottom';
};

export const TextBox = ({
	value,
	error,
	name,
	label,
	type,
	sizeClass,
	isDisabled,
	helpBlock,
	labelSmall,
	hasValid,
	placeholder,
	className,
	classWrapper,
	onValueChanged,
	isViewMode,
}: ITextBoxProps) => {
	const showError = error ? true : false;
	const classBuild = useMemo(() => {
		let classBuild = `form-control form-control-${sizeClass || 'xs'}`;
		if (showError) classBuild = `${classBuild} error`;
		if (className) classBuild = `${classBuild} ${className}`;
		if (isViewMode) classBuild = `${classBuild} ${isViewMode}`;
		return classBuild;
	}, [sizeClass, showError, className, isViewMode]);
	return (
		<>
			<div className={`form-group ${classWrapper}`}>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className={`form-control-wrap`}>
					<input
						id={name}
						type={type || 'text'}
						name={name}
						value={value}
						disabled={isDisabled || (isViewMode && isViewMode.length > 0)}
						placeholder={placeholder}
						onChange={(e) => onValueChanged(e.target.value)}
						className={classBuild}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
