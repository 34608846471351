import React, { useMemo } from 'react';
import { Button } from '../../../components/base';
import { ArticleActionRule, eArticleStatus } from '../../../context/models/articles/eArticleStatus';
import { useAuth } from '../../../utils/AuthContext';
import { useNavigate } from 'react-router-dom';

type IArticleHeaderButtonProps = {
	articleStatus: eArticleStatus;
	isOwner?: boolean;
	selectedIds: number[];
	onActionConfirm: (actionChange: eArticleStatus, selectedIds: number[]) => void;
};

export const ArticleHeaderButton = ({ articleStatus, selectedIds, onActionConfirm, isOwner }: IArticleHeaderButtonProps) => {
	const { account } = useAuth();
	const navigate = useNavigate();
	const actionsRule = useMemo(() => {
		const articleActionRoles = account?.roles;
		return ArticleActionRule({ status: articleStatus, isOwner: isOwner || false, articleActionRoles: articleActionRoles || [] });
	}, [articleStatus, isOwner, account]);

	return (
		<>
			{actionsRule.includes(eArticleStatus.EDITED) && (
				// <li>
					<Button
						text='Tạo mới'
						icon='icon ni ni-plus'
						theme='primary'
						className='d-md-inline-flex me-1'
						onClick={() => navigate(`/articles-template/edit/0`, { replace: true })}
					/>
				// </li>
			)}

			{actionsRule.includes(eArticleStatus.DELETED) && (
				<>
					{/* <li> */}
						<Button
							text={`Xóa (${selectedIds.length})`}
							icon='icon ni ni-trash'
							className='d-md-inline-flex me-1'
							theme='danger'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.DELETED, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.RESTORE) && (
				<>
					{/* <li> */}
						<Button
							text={`Khôi phục (${selectedIds.length})`}
							icon='icon ni ni-history'
							className='d-md-inline-flex me-1'
							theme='secondary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.RESTORE, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.RETURN) && (
				<>
					{/* <li> */}
						<Button
							text={`Thu hồi (${selectedIds.length})`}
							icon='icon ni ni-wallet-in'
							className='d-md-inline-flex me-1'
							theme='gray'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.RETURN, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.BACK) && (
				<>
					{/* <li> */}
						<Button
							text={`Trả bài (${selectedIds.length})`}
							icon='icon ni ni-reply-fill'
							className='d-md-inline-flex me-1'
							theme='secondary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.BACK, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.REMOVED) && (
				<>
					{/* <li> */}
						<Button
							text={`Hủy xuất bản (${selectedIds.length})`}
							icon='icon ni ni-spark-off-fill'
							className='d-md-inline-flex me-1'
							theme='danger'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.REMOVED, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.SENTEDITING) && (
				<>
					{/* <li> */}
						<Button
							text={`Gửi biên tập (${selectedIds.length})`}
							icon='icon ni ni-send-alt'
							className='d-md-inline-flex me-1'
							theme='info'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.SENTEDITING, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.EDITING) && (
				<>
					{/* <li> */}
						<Button
							text={`Nhận biên tập (${selectedIds.length})`}
							icon='icon ni ni-wallet-in'
							className='d-md-inline-flex me-1'
							theme='info'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.EDITING, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.SENTAPPROVING) && (
				<>
					{/* <li> */}
						<Button
							text={`Gửi duyệt (${selectedIds.length})`}
							icon='icon ni ni-send'
							className='d-md-inline-flex me-1'
							theme='warning'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.SENTAPPROVING, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.APPROVING) && (
				<>
					{/* <li> */}
						<Button
							text={`Nhận duyệt (${selectedIds.length})`}
							icon='icon ni ni-wallet-in'
							className='d-md-inline-flex me-1'
							theme='info'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.APPROVING, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
			{actionsRule.includes(eArticleStatus.PUBLISHED) && (
				<>
					{/* <li> */}
						<Button
							text={`Xuất bản (${selectedIds.length})`}
							icon='icon ni ni-spark-fill'
							className='d-md-inline-flex me-1'
							theme='primary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.PUBLISHED, selectedIds)}
						/>
					{/* </li> */}
				</>
			)}
		</>
	);
};
